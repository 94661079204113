import React, { useEffect, useState } from 'react'
import { ContainerProps } from '../types'
import Api from '../lib/api'
import { useRouter } from 'next/router'

type Props = {} & ContainerProps

const PassByAuthenticatedRoute = (props: Props) => {
  const { push } = useRouter()
  const [loading, setLoading] = useState<boolean | null>(null)

  useEffect(() => {
    const getMe = async () => {
      setLoading(true)

      await Api.me().then(({ errors }) => {
        if (!errors) {
          push('/')
        } else {
          setLoading(false)
        }
      })
    }

    getMe()
  }, [push])

  return loading === false ? props.children : <div />
}

export default PassByAuthenticatedRoute
