import qs from 'qs'
import getUrl, { getAppUrl } from './getUrl'

const SALESFORCE_CLIENT_ID = process.env.NEXT_PUBLIC_SALESFORCE_CLIENT_ID
const REDIRECT_URI = `${getAppUrl()}/oauth/callback`
const PRODUCTION_AUTHORIZE_LINK =
  'https://login.salesforce.com/services/oauth2/authorize'
const SANDBOX_AUTHORIZE_LINK =
  'https://test.salesforce.com/services/oauth2/authorize'

class Salesforce {
  static generateOAuthUrl(environment: string, source: string) {
    const envLink = {
      production: PRODUCTION_AUTHORIZE_LINK,
      sandbox: SANDBOX_AUTHORIZE_LINK,
    }

    const stateObject = {
      env: environment,
      src: source,
    }

    const encodedState = encodeURIComponent(JSON.stringify(stateObject))

    return `${(envLink as any)[environment]}?${qs.stringify({
      response_type: 'code',
      client_id: SALESFORCE_CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      state: encodedState,
      // prompt: 'login', // Force the user to choose an account
    })}`
  }
}

export default Salesforce
