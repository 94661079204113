import { useRouter } from 'next/router'
import styled from 'styled-components'
import React, { useContext, useState } from 'react'
import Salesforce from '../lib/salesforce'
import PassByAuthenticatedRoute from '../components/PassByAuthenticatedRoute'
import Logo from '../components/Onboarding/assets/Logo'
import { FaSalesforce } from 'react-icons/fa'
import { PiShieldCheckFill } from 'react-icons/pi'
import { PiShieldCheck } from 'react-icons/pi'
import { IoSettingsSharp } from 'react-icons/io5'
import { IoSettingsOutline } from 'react-icons/io5'
import { Popover } from 'react-tiny-popover'
import { StateStore } from '../components/Store'
import { get } from 'lodash'

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 92vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`

const EnvironmentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 110px;
  border-radius: 6px;
  padding: 2px 10px;

  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`

const Background = styled.div`
  background-color: rgb(248, 248, 248);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`

const LoginContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 475px;
  min-height: 530px;
  background-color: white;
  border-radius: 14px;
  padding: 30px;
  border: 1px solid #e4e4e7;
  margin: auto;
`

const SalesforceLoginButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 320px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 10px;
  gap: 15px;

  &:hover {
    background-color: #fafafa;
  }
`

const EnvironmentSelect = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px 0px;
  font-size: 12px;
  color: #646464;
  font-weight: 500;
  padding: 7px 7px;
  padding-bottom: 8px;
  width: 200px;
  border: 1px solid rgb(243, 243, 243);
`

const EnvironmentOption = styled.div`
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:hover {
    background-color: #f5f6f8;
    cursor: pointer;
  }
`

type LoginProps = {}

const Login = (props: LoginProps) => {
  const [environment, setEnvironment] = useState('production')
  const { push } = useRouter()
  const { state } = useContext(StateStore)

  const initialUrl = get(state, 'initialUrl', 'login')
  const navigateToSalesforceLogin = () => {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)
    }, 500)

    push(Salesforce.generateOAuthUrl(environment, initialUrl))
  }

  const [loading, setLoading] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const environmentOptions = [
    { label: 'Production', icon: <PiShieldCheck />, value: 'production' },
    { label: 'Sandbox', icon: <IoSettingsOutline />, value: 'sandbox' },
  ]

  return (
    <PassByAuthenticatedRoute>
      <CenterContainer>
        <Background />
        <LoginContainer>
          <div style={{ marginTop: '60px' }}>
            <Logo />
          </div>
          <div
            style={{ fontSize: '26px', fontWeight: '600', marginTop: '20px' }}
          >
            Welcome to Synch
          </div>
          <div
            style={{
              color: '#646464',
              fontSize: '15px',
              fontWeight: '500',
              marginTop: '5px',
            }}
          >
            Login with your existing Salesforce account
          </div>
          <SalesforceLoginButton
            onClick={() => {
              navigateToSalesforceLogin()
            }}
            style={{ marginTop: '70px' }}
          >
            <FaSalesforce size={28} color='#00A1E0' />
            <div style={{ fontWeight: '500' }}>Sign in with Salesforce</div>
          </SalesforceLoginButton>
          <Popover
            isOpen={isPopoverOpen}
            onClickOutside={() => setIsPopoverOpen(false)}
            positions={['bottom', 'top']}
            containerClassName='z-10'
            content={
              <EnvironmentSelect>
                {environmentOptions.map(environment => (
                  <EnvironmentOption
                    key={environment.value}
                    onClick={() => {
                      setEnvironment(environment.value)
                      setIsPopoverOpen(false)
                    }}
                  >
                    {environment.icon}
                    <span style={{ marginLeft: '8px' }}>
                      {environment.label}
                    </span>
                  </EnvironmentOption>
                ))}
              </EnvironmentSelect>
            }
          >
            <EnvironmentContainer
              onClick={() => {
                setIsPopoverOpen(true)
              }}
            >
              {environment === 'production' ? (
                <PiShieldCheckFill />
              ) : (
                <IoSettingsSharp />
              )}
              <span
                style={{
                  marginLeft: '8px',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {environment === 'production' ? 'Production' : 'Sandbox'}
              </span>
            </EnvironmentContainer>
          </Popover>
        </LoginContainer>
      </CenterContainer>
    </PassByAuthenticatedRoute>
  )
}

export default Login
