import React, { useState } from 'react'

const Logo = ({ scale = 1 }: any) => {
  const originalWidth = 70
  const originalHeight = 68

  const width = originalWidth * scale
  const height = originalHeight * scale

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M64 5H6V48.5H7.03571V53.5H6V63H64V20.5H62.9643V15.5H64V5ZM24.0693 15.5C22.4124 15.5 21.0693 16.8431 21.0693 18.5V34.7909C21.0693 36.4478 22.4124 37.7909 24.0693 37.7909H44.209V48.5H21H20.3929V53.5H21H46.209C47.8658 53.5 49.209 52.1568 49.209 50.5V35.7909C49.209 34.1341 47.8658 32.7909 46.209 32.7909H26.0693V20.5H49H49.6071V15.5H49H24.0693ZM52.0357 20.5H50.8214V15.5H52.0357V20.5ZM54.4643 20.5H53.25V15.5H54.4643V20.5ZM56.8929 20.5H55.6786V15.5H56.8929V20.5ZM59.3214 20.5H58.1071V15.5H59.3214V20.5ZM61.75 20.5H60.5357V15.5H61.75V20.5ZM8.25 53.5H9.46429V48.5H8.25V53.5ZM10.6786 53.5H11.8929V48.5H10.6786V53.5ZM13.1071 53.5H14.3214V48.5H13.1071V53.5ZM15.5357 53.5H16.75V48.5H15.5357V53.5ZM17.9643 53.5H19.1786V48.5H17.9643V53.5Z'
        fill='black'
      />
    </svg>
  )
}

export default Logo
